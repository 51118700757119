import React from 'react';
import Image from 'gatsby-image';
import { FaAngleDoubleDown } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ContactForm from '../components/Form';
import Location from '../components/Location';
import { useGraphQL } from '../hooks';

const ContactPage = () => {
  const data = useGraphQL();
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="relative">
        <Image
          style={{ height: `50vh`, minHeight: `22rem` }}
          fluid={data.contactHero.childImageSharp.fluid}
          alt="Front on shot of 3 townhouses"
          loading="eager"
        />
        <div className="absolute inset-0 flex items-end justify-center max-w-3xl p-4 mx-auto">
          <AnchorLink
            className="flex items-center justify-center px-2 py-1 leading-none bg-white"
            href="#contact"
            offset="56"
          >
            <FaAngleDoubleDown className="text-green-500" />
          </AnchorLink>
        </div>
      </div>
      <section
        id="contact"
        className="flex flex-wrap w-full mx-auto mt-8 max-w-1920px"
      >
        <div className="w-full p-4 sm:px-12 lg:px-24 md:w-1/2">
          <h1 className="w-full mb-4 text-4xl font-bold leading-none text-green-500 uppercase">
            Contact
          </h1>
          <div className="pl-4 text-gray-600 border-l-4 border-green-500 lg:text-xl">
            <p className="mb-4">
              Ready to get your building project rolling but need to know more?
              Let’s start the conversation here.
            </p>
            <p className="mb-4">
              Send us your questions. And we’ll get you the answers!
            </p>
            <p className="font-bold text-gray-700 hover:text-green-500">
              <a href={`tel:${data.site.siteMetadata.email}`}>
                {data.site.siteMetadata.email}
              </a>
            </p>
          </div>
        </div>
        <div className="w-full p-4 sm:px-12 lg:text-xl md:w-1/2">
          <div className="pl-4 border-l-4 border-transparent">
            <h2 className="w-full max-w-lg mx-auto mb-4 font-bold text-gray-800 uppercase">
              Enquiry
            </h2>
            <ContactForm />
          </div>
        </div>
        <Location />
      </section>
    </Layout>
  );
};

export default ContactPage;
